<script lang="ts" setup>
const props = defineProps({
  color: { default: "#c4c4c4", required: false, type: String },
  width: { default: "100%", required: false, type: String },
});
</script>
<template>
  <div
    :style="{ 'max-width': props.width, backgroundColor: props.color }"
    class="divider"
  ></div>
</template>
<style lang="scss" scoped>
.divider {
  height: 1px;
  width: 100%;
}
</style>
